<template>
  <cs-parallax-canvas class="cs-page-home">
    <template
      v-for="(segment, id, index) in $store.state.children">
        <component
          :is="'cs-page-' + segment.head.blueprint"
          :key="id"
          :hash="segment.head.slug"
          :positionZ="positionZ(index)"
          :content="segment.content"
          class="cs-page-home-segment" />
    </template>
  </cs-parallax-canvas>
</template>

<script>
import Vue from 'vue'
import CsParallaxCanvas from '@/components/elements/ParallaxCanvas'

/**
 * load all components from subfolder home/
 * names of components: 'cs-page-' + blueprint-name
 */
var Segments = require.context('@/components/pages/home', true, /^\.\/.*\.(vue)$/)
Segments.keys().forEach((path) => {
  var Component = Segments(path)
  Component = (Component.default || Component)
  Vue.component(fn.pascalCase(Component.name), Component)
})

export default {
  name: 'cs-page-home',
  components: {
    CsParallaxCanvas
  },
  methods: {

    /**
     * z-index is assigned like 1 - 5 - 2 - 6 - 3 - 7 - 4
     * if any special values are needed, the z-index can be added to
     * kirby blueprint in cs_config and taken from there
     */
    positionZ (index) {
      var res = Math.ceil((index + 1) / 2)
      if (index % 2 !== 0) {
        res +=  Math.ceil(Object.keys(this.$store.state.children).length / 2)
      }
      return res
    }
  }
}
</script>

<style lang="sass">
// .cs-page-home
</style>