<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    class="cs-page-home-events">
      <div class="intro">
        <cs-row class="text">
          <cs-col class="is-8 is-offset-2 is-10-md is-offset-1-md is-12-sm is-no-offset-sm">
            <h2>{{ content.headline }}</h2>
          </cs-col>
        </cs-row>
      </div>
      <div class="list">
        <cs-element-event v-for="(event, i) in events" :key="i" :event="event.content" />
        <cs-slide :active="showMore">
          <cs-element-event v-for="(event, i) in more" :key="i" :event="event.content" />
        </cs-slide>
      </div>
      <div v-if="hasMore && !showMore" class="more">
        <cs-row>
          <cs-col>
            <cs-button
              @click="showMoreHandler($event)"
              class="is-primary is-medium is-text"
              icon="scroll">
                {{ content.buttonmore | replace('{count}', more.length) }}
            </cs-button>
          </cs-col>
        </cs-row>
      </div>
  </cs-parallax-segment>
</template>

<script>
import Deferred from '@/services/Deferred'
import CsParallaxSegment from '@/components/elements/ParallaxSegment'
import CsElementEvent from '@/components/elements/Event'

export default {
  name: 'cs-page-home-events',
  components: {
    CsParallaxSegment,
    CsElementEvent
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    }
  },
  data() {
    return {
      show: 3,
      limit: 20,
      events: [],
      more: [],
      showMore: false,
      loadEventsPromise: null
    }
  },
  created () {
    this.loadEvents()
  },
  mounted () {
    this.$trigger('home/load-promise', this.loadEventsPromise)
  },
  computed: {
    hasMore () {
      return this.more.length > 0
    }
  },
  methods: {
    loadEvents () {
      this.loadEventsPromise = this.$api.children('/events', {
        order: 'asc',
        limit: this.limit,
        filter: [ 'date.gte.today' ]
      })
        .then((response) => {
          let show = response.data.children.length > (this.show + 1)
            ? this.show
            : this.limit
          this.events = response.data.children.slice(0, show)
          this.more = response.data.children.slice(show)
        })
        .catch(() => {
          this.events = []
        })
    },
    showMoreHandler (Event) {
      this.showMore = true
    }
  }
}
</script>

<style lang="sass">
.cs-page-home-events
  display: flex
  justify-content: center
  padding-top: headerheight()
  padding-bottom: m(6)
  background-color: white()
  .intro
    margin-bottom: m(3)
    h2
      margin-bottom: m(3)
      text-align: center
  .more
    .cs-col
      display: flex
      justify-content: center

+md
  .cs-page-home-events
    padding-top: headerheight('md')

+sm
  .cs-page-home-events
    padding-top: headerheight('sm')

+xs
  .cs-page-home-events
    padding-top: headerheight('xs')

</style>
