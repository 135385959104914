<template>
  <div class="cs-template-home">
    <cs-segment-header />
    <cs-page-home />
    <cs-segment-slideshow />
    <cs-overlay :visible="overlayVisible" :spinner="true" />
  </div>
</template>

<script>
import CsSegmentHeader from '@/components/segments/Header'
import CsSegmentSlideshow from '@/components/segments/Slideshow'
import CsPageHome from '@/components/pages/Home'
import csOverlay from '@/components/elements/Overlay'

export default {
  name: 'cs-template-home',
  components: {
    CsSegmentHeader,
    CsSegmentSlideshow,
    CsPageHome,
    csOverlay
  },
  data () {
    return {
      loadPromises: [],
      overlayVisible: false
    }
  },
  mounted () {
    this.scrollMounted()
  },
  methods: {
    loadPromiseHandler (Event) {
      this.loadPromises.push(Event.detail)
    },
    scrollMounted () {
      this.overlayVisible = true
      Promise.all(this.loadPromises)
        .then(() => {
          window.setTimeout(() => {
            this.overlayVisible = false
            this.$scroll.do('router/scroll-mounted')
          }, 500) // wait 500 until render is finished, @Safari
        })
    }
  },
  events: {
    'home/load-promise': 'loadPromiseHandler'
  }
}
</script>

<style lang="sass">
.cs-template-home
  z-index: $z-index-base
  .cs-overlay
    z-index: $z-index-header - 1
</style>
