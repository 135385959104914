<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    :fullWidth="true"
    :fitContent="true"
    :speedFore="2"
    class="cs-page-home-contact">
      <template #background>
        <cs-image
          :file="content.pageimage"
          :deferred="pageImagePromise"
          size="home.teaser"
          class="is-hero" />
      </template>
      <template #foreground>
         <div class="contact">
          <cs-row class="text">
            <cs-col class="is-8 is-offset-2 is-10-md is-offset-1-md is-12-sm is-no-offset-sm">
              <h2>{{ content.headline }}</h2>
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-4">
              <div class="box">
                <div class="typo">
                  <h3>{{ content.headlineaddress }}</h3>
                </div>
                <div class="address typo">
                  <cs-text :content="content.address" />
                  <div>
                    <p>Tel.: {{ content.telephone.title }}</p>
                    <p><cs-link :link="content.email" /></p>
                  </div>
                </div>
              </div>
            </cs-col>
            <cs-col class="is-4">
              <div class="box">
                <div class="typo">
                  <h3>{{ content.headlinebooking }}</h3>
                  <cs-text :content="content.copybooking" />
                </div>
                <cs-link :link="content.emailbooking">
                  <cs-button class="is-text is-primary is-medium" icon="mail">{{ content.buttonbooking }}</cs-button>
                </cs-link>
              </div>
            </cs-col>
            <cs-col class="is-4">
              <div class="box">
                <div class="typo">
                  <h3>{{ content.headlinepress }}</h3>
                  <cs-text :content="content.copypress" />
                  <ul>
                    <li v-for="(download, i) in content.downloadspress" :key="i">
                      <cs-link :link="download.link" />
                    </li>
                  </ul>
                </div>
              </div>
            </cs-col>
          </cs-row>
        </div>
        <div class="footer">
          <cs-links
            v-if="$store.state.navigation.footer.length > 0"
            :links="$store.state.navigation.footer"
            :maxLevel="1"
            class="nav-footer" />
        </div>
      </template>
  </cs-parallax-segment>
</template>

<script>
import Deferred from '@/services/Deferred'
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-contact',
  components: {
    CsParallaxSegment
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    }
  },
  data() {
    return {
      pageImagePromise: new Deferred()
    }
  },
  mounted () {
    this.$trigger('home/load-promise', this.pageImagePromise)
  },
}
</script>

<style lang="sass">
.cs-page-home-contact
  height: 100vh
  .fore
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: headerheight()
    padding-bottom: m(6)
    .contact
      flex-shrink: 0
      h2
        text-align: center
        color: black()
        margin-bottom: m(6)
      .box
        display: flex
        flex-direction: column
        justify-content: flex-start
        height: 100%
        padding: m(3)
        background-color: white(.7)
        h3
          color: black()
        p
          color: black()
        .cs-button
          margin-top: m(1)
        ul
          list-style-type: none
          margin-left: 0
    .footer
      flex-shrink: 0
      .nav-footer
        display: flex
        justify-content: center
        flex-wrap: wrap
        li
          padding: 0 m(1)
          +font('small', 'semibold')
          background-color: white(.7)
          a
            color: black()

+md
  .cs-page-home-contact
    .fore
      padding-top: headerheight('md')

+until(840px)
  .cs-page-home-contact
    .fore
      .contact
        .cs-row
          flex-wrap: wrap
          .cs-col
            width: 100%
        .box
          .address
            display: flex
            div
              margin-right: m(4)
          ul
            display: flex
            li
              margin-right: m(2)

+sm
  .cs-page-home-contact
    .fore
      padding-top: headerheight('sm')
      padding-bottom: m(3)

+xs
  .cs-page-home-contact
    .fore
      padding-top: headerheight('xs')
      padding-bottom: m(10)
      .contact
        .cs-row
          flex-wrap: wrap
          .cs-col
            width: 100%
        .box
          .address
            display: block
</style>
