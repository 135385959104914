<template>
  <div
    :class="{
      'has-error': hasError,
      'is-disabled': disabled
    }"
    class="cs-textarea">
      <textarea
        v-model="inputValue"
        :placeholder="placeholder"
        :maxlength="max"
        :disabled="disabled"
        @input="inputHandler($event)"
        @change="changeHandler($event)"
        @keydown="keyDownHandler($event)"></textarea>
      <span v-if="hasHelp" class="help">
        {{ help }}
      </span>
  </div>
</template>

<script>
export default {
  name: 'cs-textarea',
  props: {
    value: {
      type: String,
      default: ''
    },

    /**
     * optional store action/mutation on value change
     * { action: foo, mutation: bar [, more: callback-params]}
     * otherwise value will be emitted via v-model
     */ 
    callback: {
      type: Object
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      text: null,
      inputValue: ''
    }
  },
  computed: {
    hasError () {
      return this.error || !this.valid(this.inputValue)
    },
    hasHelp () {
      return fn.isString(this.help)
    }
  },
  methods: {
    valid (value) {
      return fn.isEmpty(value) || fn.isString(value, this.min, this.max)
    },
    format (text) {
      return fn.trim(text)
    },
    unformat (input) {
      return fn.toString(input)
    },
    typecheck (Event) {
      if (fn.getFunctionKey(Event)) {
        return true
      }
      return true
    },
    inputHandler (Event) {
      if (!this.disabled) {
        this.text = this.unformat(this.inputValue)
        this.publish(this.text || null, 'input')
      }
    },
    changeHandler (Event) {
      if (!this.disabled && this.valid(this.inputValue)) {
        this.inputValue = this.format(this.text)
        this.publish(this.text, 'change')
      }
    },
    keyDownHandler (Event) {
      if (!this.typecheck(Event)) {
        event.preventDefault()
      }
    },
    publish (value, event) {
      if (fn.isObject(this.callback)) {
        var params = fn.assign({
          value: value,
          event: event
          }, this.callback
        )
        if(fn.isString(this.callback.action)) {
          this.$store.dispatch(this.callback.action, params)
        } else if(fn.isString(this.callback.mutation)) {
          this.$store.commit(this.callback.mutation, params)
        } else {
          info('wrong parameter for callback in textarea control')
        }
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(text) {
        if (text !== this.text) {
          this.text = text
          this.inputValue = this.format(text)
        }
      }
    }
  }
}
</script>

<style lang="sass">
.cs-textarea
  position: relative
  display: flex
  align-items: flex-start
  textarea
    +input
    resize: vertical
    &:not([rows])
      height: $form-textarea-height
      min-height: $form-textarea-height
      max-height: $form-textarea-height * 3
    &[rows]
      height: initial
    &:focus
      border-color: $form-control-color
      box-shadow: 0 0 0 0.125em rgba($form-control-color, 0.25)
  &.is-disabled
    textarea
      +disabled
  &:not(.is-disabled)
    &.has-error
      textarea
        +error
  &.has-fixed-size
    textarea
      resize: none
  &.is-small
    textarea
      &:not([rows])
        height: $form-textarea-height * .5
        min-height: $form-textarea-height * .5
        max-height: $form-textarea-height * 1.5
  &.is-medium
    textarea
      &:not([rows])
        height: $form-textarea-height * 1.5
        min-height: $form-textarea-height * 1.5
        max-height: $form-textarea-height * 4.5
  &.is-big
    textarea
      &:not([rows])
        height: $form-textarea-height * 2
        min-height: $form-textarea-height * 2
        max-height: $form-textarea-height * 6
  &.help
    +font('default')
    color: grey(5)
    +ellipsis
</style>