<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    :fullWidth="true"
    :fitContent="true"
    :speedBack="-3"
    class="cs-page-home-media">
      <template #background>
        <cs-image
          :file="content.pageimage"
          :deferred="pageImagePromise"
          size="home.teaser"
          class="is-hero" />
      </template>
      <template #foreground>
        <div class="intro">
          <cs-row class="text">
            <cs-col class="is-8 is-offset-2 is-10-md is-offset-1-md is-12-sm is-no-offset-sm">
              <h2>{{ content.headline }}</h2>
            </cs-col>
          </cs-row>
        </div>
        <div class="media">
          <cs-row class="is-multiline videos">
            <cs-col class="is-5 video">
              <div class="box">
                <cs-video :video="content.videos[0]" />
              </div>
              <div class="title typo">
                {{ content.videos[0].title }}
              </div>
            </cs-col>
            <cs-col class="is-5 video">
              <div class="box">
                <cs-video :video="content.videos[1]" />
              </div>
              <div class="title typo">
                {{ content.videos[1].title }}
              </div>
            </cs-col>
          </cs-row>
          <cs-row class="links">
            <cs-col>
              <cs-link :link="content.channelurl">
                <cs-button
                  icon="video"
                  class="is-white is-medium is-text">
                    {{ content.buttonvideos }}
                </cs-button>
              </cs-link>
              <cs-button
                @click="clickSlideshowHandler($event)"
                icon="photo"
                class="is-white is-medium is-text">
                  {{ content.buttonphotos }}
                </cs-button>
            </cs-col>
          </cs-row>
        </div>
      </template>
  </cs-parallax-segment>
</template>

<script>
import Deferred from '@/services/Deferred'
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-media',
  components: {
    CsParallaxSegment
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    }
  },
  data() {
    return {
      pageImagePromise: new Deferred()
    }
  },
  mounted () {
    this.$trigger('home/load-promise', this.pageImagePromise)
  },
  methods: {
    clickSlideshowHandler (Event) {
      this.$trigger('slideshow/open', {
        files: this.content.images
      })
    }
  }
}
</script>

<style lang="sass">
.cs-page-home-media
  height: 100vh
  .fore
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: headerheight()
    padding-bottom: m(10)
    .intro
      flex-shrink: 0
      h2
        margin-bottom: m(3)
        text-align: center
        color: white()
    .media
      flex-shrink: 0
      .videos
        justify-content: center
        margin-bottom: m(3)
        .video
          min-width: 480px
          .box
            padding: 6px
            background-color: white()
          .title
            padding-top: m(1)
            +font('semibold')
            color: white()
      .links
        .cs-col
          display: flex
          justify-content: center
          .cs-button
            margin: 0 m(1)


+md
  .cs-page-home-media
    .fore
      padding-top: headerheight('md')

+sm
  .cs-page-home-media
    .fore
      padding-top: headerheight('sm')
      padding-bottom: m(3)

+xs
  .cs-page-home-media
    .fore
      padding-top: headerheight('xs')
      .media
        .videos
          .video
            min-width: 100%
</style>
