<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    class="cs-page-home-cd">
      <div class="intro">
        <cs-row class="text">
          <cs-col class="is-8 is-offset-2 is-10-md is-offset-1-md is-12-sm is-no-offset-sm">
            <h2>{{ content.headline }}</h2>
            <cs-text :content="content.copy" />
          </cs-col>
        </cs-row>
      </div>
      <div class="products">
        <cs-row class="is-multiline is-xs">
          <cs-col v-for="(product, i) in $store.state.shop.products" :key="i" class="product typo">
            <cs-image :file="product.pageimage" size="product" />
            <div class="details">
              <div class="title">{{ product.title }}</div>
              <div class="info">
                <span>{{ product.year}}, {{ product.duration}} min.</span>
                <cs-button class="is-icon is-primary is-narrow" icon="info" @click="titlelistHandler(product, i)" />
              </div>
              <div class="price">{{ product.price | currency }}</div>
              <div class="quantity">
                <span class="label">{{ $t('shop.quantity') }}:</span>
                <cs-select
                  v-model="product.quantity"
                  :options="quantityOptions"
                  @input="changeQuantityHandler()" />
              </div>
            </div>
          </cs-col>
        </cs-row>
      </div>
      <cs-slide :active="showTitlelist >= 0">
        <div class="titlelist typo" ref="titlelist">
          <cs-row>
            <cs-col>
              <h3>{{ productInfoTitle }}</h3>
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-5 is-offset-1 is-6-sm is-no-offset-sm left">
              <cs-list :items="titlelistLeft" component="ol" />
            </cs-col>
            <cs-col class="is-5 is-6-sm right">
              <cs-list :items="titlelistRight" component="ol" :start="titlelistLeft.length + 1" />
            </cs-col>
          </cs-row>
        </div>
      </cs-slide>
      <cs-slide :active="$store.state.shop.quantity > 0">
        <div class="basket">
          <cs-row>
            <cs-col>
              <span class="total">{{ $t('shop.total')}} {{ $store.state.shop.total | currency }}</span>
              <span class="shipping">({{ $t('shop.shipping' )}})</span>
            </cs-col>
          </cs-row>
        </div>
      </cs-slide>
      <cs-slide :active="$store.state.shop.showForm" @openend="openFormHandler($event)">
        <div class="form" ref="form">
          <cs-row>
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.salutation.required">
                  {{ $t('shop.salutation.label') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-2 is-3-sm control">
              <cs-select
                v-model="$store.state.shop.data.salutation.value"
                :options="$store.state.shop.data.salutation.options"
                :lang="$t('shop.salutation.options')"
                :error="$store.state.shop.data.salutation.error">
              </cs-select>
            </cs-col>
          </cs-row>
          <cs-row class="is-xs is-multiline">
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-12-xs is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.prename.required || $store.state.shop.data.surname.required">
                  {{ $t('shop.name') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-2 is-3-md is-4-sm is-6-xs control">
              <cs-input
                v-model="$store.state.shop.data.prename.value"
                :error="$store.state.shop.data.prename.error" />
            </cs-col>
            <cs-col class="is-2 is-3-md is-4-sm is-6-xs control">
              <cs-input
                v-model="$store.state.shop.data.surname.value"
                :error="$store.state.shop.data.surname.error" />
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.organisation.required">
                  {{ $t('shop.organisation') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-4 is-6-md is-8-sm control">
              <cs-input
                v-model="$store.state.shop.data.organisation.value"
                :error="$store.state.shop.data.organisation.error" />
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.address.required">
                  {{ $t('shop.address') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-4 is-6-md is-8-sm control">
              <cs-input
                v-model="$store.state.shop.data.address.value"
                :error="$store.state.shop.data.address.error" />
            </cs-col>
          </cs-row>
          <cs-row class="is-xs is-multiline">
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-12-xs is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.zip.required || $store.state.shop.data.town.require">
                  {{ $t('shop.town') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-1 is-2-md is-3-xs control">
              <cs-input
                v-model="$store.state.shop.data.zip.value"
                :error="$store.state.shop.data.zip.error" />
            </cs-col>
            <cs-col class="is-3 is-4-md is-6-sm is-9-xs control">
              <cs-input
                v-model="$store.state.shop.data.town.value"
                :error="$store.state.shop.data.town.error" />
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.country.required">
                  {{ $t('shop.country') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-4 is-6-md is-8-sm control">
              <cs-input
                v-model="$store.state.shop.data.country.value"
                :error="$store.state.shop.data.country.error" />
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.email.required">
                  {{ $t('shop.email') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-4 is-6-md is-8-sm control">
              <cs-input
                v-model="$store.state.shop.data.email.value"
                :error="$store.state.shop.data.email.error"
                :isEmail="true" />
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-2 is-offset-3 is-2-md is-offset-2-md is-4-sm is-no-offset-sm">
              <cs-label
                :required="$store.state.shop.data.message.required">
                  {{ $t('shop.message') }}
              </cs-label>
            </cs-col>
            <cs-col class="is-4 is-6-md is-8-sm control">
              <cs-textarea
                v-model="$store.state.shop.data.message.value"
                :error="$store.state.shop.data.message.error"
                class="is-small" />
            </cs-col>
          </cs-row>
          <cs-row>
            <cs-col class="is-6 is-offset-3 is-8-md is-offset-2-md is-12-sm is-no-offset-sm">
              <cs-checkbox
                v-model="$store.state.shop.data.conditions.value"
                :error="$store.state.shop.data.conditions.error">
                  <cs-text :content="content.conditions" class="typo" />
              </cs-checkbox>
            </cs-col>
          </cs-row>
        </div>
      </cs-slide>
      <cs-slide :active="showInputError">
        <div class="error">
          <cs-row class="text">
            <cs-col class="is-8 is-offset-2  is-12-sm is-no-offset-sm">
              <cs-text :content="content.copyinputerror" />
            </cs-col>
          </cs-row>
        </div>
      </cs-slide>
      <cs-slide :active="showError">
        <div class="error">
          <cs-row class="text">
            <cs-col class="is-8 is-offset-2  is-12-sm is-no-offset-sm">
              <cs-text :content="content.copyerror" />
              <p>(Error: {{ errno }})</p>
            </cs-col>
          </cs-row>
        </div>
      </cs-slide>
      <cs-slide :active="$store.state.shop.quantity > 0">
        <div class="action">
          <cs-row>
            <cs-col>
              <cs-button
                class="is-primary is-medium"
                :spinner="onSubmit"
                @click="formActionHandler($event)">
                  <span v-if="$store.state.shop.showForm">{{ content.buttonsubmit }}</span>
                  <span v-else>{{ content.buttonorder }}</span>
              </cs-button>
            </cs-col>
          </cs-row>
        </div>
      </cs-slide>
      <cs-slide :active="showSuccess">
        <div class="success">
          <cs-row class="text">
            <cs-col class="is-8 is-offset-2 is-12-sm is-no-offset-sm">
              <cs-text :content="content.copysuccess" />
            </cs-col>
          </cs-row>
        </div>
      </cs-slide>
  </cs-parallax-segment>
</template>

<script>
import Deferred from '@/services/Deferred'
import CsParallaxSegment from '@/components/elements/ParallaxSegment'
import CsSelect from '@/components/controls/Select'
import CsInput from '@/components/controls/Input'
import CsTextarea from '@/components/controls/Textarea'
import CsCheckbox from '@/components/controls/Checkbox'
import CsLabel from '@/components/controls/Label'
import { headerheight } from '@/config/style.json'

export default {
  name: 'cs-page-home-cd',
  components: {
    CsParallaxSegment,
    CsSelect,
    CsInput,
    CsTextarea,
    CsCheckbox,
    CsLabel
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    }
  },
  data() {
    return {
      errno: 0,
      showSuccess: false,
      showInputError: false,
      showError: false,
      onSubmit: false,
      showTitlelist: -1,
      infoProduct: null
    }
  },
  created () {
    this.$store.commit('shop/initForm')
    this.init()
  },
  mounted () {
    this.init()
  },
  computed: {
    quantityOptions () {
      var res = []
      for(var i = 0; i <= this.$store.state.shop.maxQuantity; i++) {
        res.push({ key: i })
      }
      return res
    },
    productInfoTitle () {
      return this.showTitlelist >= 0 ? this.infoProduct.title : ''
    },
    titlelistLeft () {
      if (this.showTitlelist >= 0) {
        return this.getTitlelist(
         this.infoProduct.titlelist.slice(0, Math.ceil(this.infoProduct.titlelist.length / 2))
        )
      }
      return []
    },
    titlelistRight () {
      if (this.showTitlelist >= 0) {
        return this.getTitlelist(
          this.infoProduct.titlelist.slice(Math.ceil(this.infoProduct.titlelist.length / 2))
        )
      }
      return []
    }
  },
  methods: {
    init () {
      this.errno = 0
      this.showSuccess = false
      this.showError = false
      this.showInputError = false
      this.onSubmit = false
    },
    initForm () {
      this.scrollToForm()
        .finally(() => {
          this.$store.commit('shop/showForm')
          this.showSuccess = false
          this.showError = false
          this.showInputError = false
        })
    },
    initBasket () {
      this.$store.commit('shop/initBasket')
    },
    getTitlelist (titles) {
      var res = []
      fn.each(titles, title => {
        var entry = '<strong>' + title.title + '</strong>'
        if (title.arranger) {
          entry += ' (arr. ' + title.arranger + ')'
        }
        res.push(entry)
      })
      return res
    },
    calculateBasket () {
      this.$store.commit('shop/calculateBasket')
      this.showSuccess = false
      if (this.$store.state.shop.showForm && this.$store.state.shop.quantity === 0) {
        this.$store.commit('shop/hideForm')
        this.showError = false
        this.showInputError = false
      }
    },
    submitForm () {
      this.errno = 0
      this.onSubmit = true
      this.showError = false
      this.showInputError = false
      this.$store.dispatch('shop/submit')
        .then(() => {
          this.initBasket()
          this.onSubmit = false
          this.$store.commit('shop/hideForm')
          this.scrollToForm()
            .then(() => {
              this.showSuccess = true
            })
        })
        .catch((reason) => {
          this.onSubmit = false
          if (!reason.status || reason.status >= 10) {
            this.showError = true
            this.errno = reason.status || 10
          } else if (reason.status === 2) {
            this.showInputError = true
          }
        })
    },
    scrollToTitlelist () {
      var bp = this.$dom.getBreakpoint()
      return this.$scroll.to(this.$refs.titlelist, {
        offset: headerheight[bp] * -1 // only possible with pixels
      }).catch(() => {})
    },
    scrollToForm () {
      var bp = this.$dom.getBreakpoint()
      return this.$scroll.to(this.$refs.form, {
        offset: headerheight[bp] * -1 // only possible with pixels
      }).catch(() => {})
    },
    changeQuantityHandler () {
      this.calculateBasket()
    },
    openFormHandler (Event) {
      this.scrollToForm()
    },
    titlelistHandler (product, key) {
      if (this.showTitlelist === key) {
        this.showTitlelist = -1
        return
      }
      var wait = this.showTitlelist >= 0 ? 500 : 0
      this.showTitlelist = -1
      window.setTimeout(() => {
        this.infoProduct = product
        this.showTitlelist = key
        this.scrollToTitlelist()
      }, wait)
    },
    formActionHandler (Event) {
      if (this.$store.state.shop.quantity > 0) {
        if (this.$store.state.shop.showForm) {
          this.submitForm()
        } else {
          this.initForm()
        }
      }
    }
  }
}
</script>

<style lang="sass">
.cs-page-home-cd
  display: flex
  justify-content: center
  padding-top: headerheight()
  padding-bottom: m(9)
  background-color: white()
  .intro
    padding-bottom: m(3)
    h2
      padding-bottom: m(3)
      text-align: center
    .cs-text
      text-align: center
  .products
    .cs-row
      justify-content: center
      .product
        display: flex
        flex-direction: column
        align-items: center
        flex: 0 1 auto
        width: 260px
        padding-bottom: m(3)
        .details
          display: flex
          flex-direction: column
          align-items: center
          .title
            display: block
            width: 240px
            padding-top: m(1)
            +font('semibold', 'medium')
            +ellipsis
            text-align: center
          .info
            display: flex
            justify-content: center
            align-items: center
            width: 100%
          .price
            +font('semibold', 'medium')
          .quantity
            display: flex
            justify-content: center
            width: 100%
            padding-top: m(1)
            .label
              margin-right: m(1)
            .cs-select
              width: 70px
              padding-top: 1px
              padding-bottom: 1px
  .titlelist
    padding-top: m(3)
    h3
      text-align: center
  .basket
    .cs-col
      display: flex
      justify-content: center
      align-items: center
      .total
        +font('medium', 'semibold')
      .shipping
        margin-left: m(1)
  .form
    padding-top: m(3)
  .error
    padding-top: m(3)
    padding-bottom: m(3)
    .cs-col
      background-color: color('warning')
      text-align: center
      *
        +font('semibold')
        color: white()
  .action
    .cs-col
      display: flex
      justify-content: center
      align-items: center
  .success
    padding-top: m(12)
    .cs-col
      background-color: color('success')
    .cs-text
      text-align: center
      *
        +font('semibold')
        color: white()
  
+md
  .cs-page-home-cd
    padding-top: headerheight('md')
    .products
      .cs-row
        .product
          width: 200px
          .details
            .title
              width: 180px
              +font('semibold', 'default')
            .price
              +font('semibold', 'default')

+sm
  .cs-page-home-cd
    padding-top: headerheight('sm')

+xs
  .cs-page-home-cd
    padding-top: headerheight('xs')
    .products
      .cs-row
        .product
          width: 180px
          .info
            .title
              width: 160px
    .titlelist
      .left
        padding-bottom: 0
        text-align: center
      .right
        padding-top: 0
        text-align: center
      ol
        margin-left: 0
        list-style-position: inside !important
</style>
