<template>
  <cs-segment class="cs-segment-header">
    <cs-container>
      <cs-navigation />
      <cs-svg name="sprite-logo-head" />
    </cs-container>
  </cs-segment>
</template>

<script>
import CsNavigation from '@/components/elements/Navigation'

export default {
  name: 'cs-segment-header',
  components: {
    CsNavigation
  },
  props: {},
  data() {
    return {}
  },
  computed: {}
}
</script>

<style lang="sass">
.cs-segment-header
  position: fixed
  left: 0
  right: 0
  width: 100%
  height: headerheight()
  padding-top: m(3)
  background: linear-gradient(180deg, color('primary', .7) 0%, color('primary', .7) 70%, transparent 100%)
  z-index: $z-index-header
  .cs-container
    position: relative
    .cs-svg
      position: absolute
      top: m(3)
      right: content-padding()
      width: m(18)

+md
  .cs-segment-header
    height: headerheight('md')
    .cs-container
      .cs-svg
        right: content-padding('md')
        width: m(14)

+sm
  .cs-segment-header
    height: headerheight('sm')
    .cs-container
      .cs-svg
        top: 0
        right: 50%
        width: m(12)
        transform: translateX(50%)

+xs
  .cs-segment-header
    height: headerheight('xs')
    .cs-container
      .cs-svg
        top: - m(1)
        width: m(10)
</style>
