<template>
  <div
    :class="{
      'has-error': hasError,
      'is-disabled': disabled,
      'is-blinking': isBlinking
    }"
    class="cs-select">
      <span class="selected-option">
        &nbsp;{{ selectedLabel }}&nbsp;
      </span>
      <span class="dropdown-icon"></span>
      <select
        v-model="inputValue"
        :disabled="disabled"
        @change="changeHandler($event)">
          <option
            v-for="(option) in options"
            :key="option.key"
            :value="option.key"
            :disabled="option.disabled">
              {{ label(option) }}
          </option>
      </select>
      <span v-if="hasHelp" class="help">
        {{ help }}
      </span>
  </div>
</template>

<script>
export default {
  name: 'cs-select',
  props: {
    value: {
      default: ''
    },

    /**
     * optional store action/mutation on value change
     * { action: foo, mutation: bar [, more: callback-params]}
     * otherwise value will be emitted via v-model
     */ 
    callback: {
      type: Object
    },
    options: {
      type: Array // with key & disabled
    },
    lang: {
      type: Object // @see method label()
    },
    lookup: { // @see method label()
      type: String,
      default: 'label'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    help: {
      type: String,
      default: ''
    },
    changed: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedIndex: -1,
      inputValue: null,
      isBlinking: false
    }
  },
  computed: {

    /**
     * selectedLabel is set, when the changed key is emmited and the new
     * value is set from outside.
     */
    selectedLabel () {
      if (this.options[this.selectedIndex]) {
        return this.label(this.options[this.selectedIndex], true)
      }
      return ''
    },
    hasError () {
      return this.error
    },
    hasHelp () {
      return fn.isString(this.help)
    }
  },
  methods: {

    /**
     * Ways to get the label from:
     * 1. from lang-object: key: { lookup: value }
     * 2. from lang-object: key: value
     * 3. from options-object: [ key, lookup ]
     * 4. key
     */ 
    label (option, test) {
      if (fn.has(this.lang, option.key)) {
        return fn.isObject(this.lang[option.key]) ? this.lang[option.key][this.lookup] : this.lang[option.key]
      } else if (fn.has(option, this.lookup)) {
        return option[this.lookup]
      }
      return option.key
    },
    blink () {
      this.isBlinking = true
      window.setTimeout(() => {
        this.isBlinking = false
      }, 300)
    },
    setSelectedIndex () {
      fn.each(this.options, (option, index) => {
        if (option.key === this.value) {
          this.selectedIndex = index
        }
      })
    },
    changeHandler (Event) {
      if (!this.disabled) {
        this.publish(this.inputValue)
      }
    },
    publish (value) {
      if (fn.isObject(this.callback)) {
        var params = fn.assign({
          value: value,
          event: 'change'
          }, this.callback
        )
        if(fn.isString(this.callback.action)) {
          this.$store.dispatch(this.callback.action, params)
        } else if(fn.isString(this.callback.mutation)) {
          this.$store.commit(this.callback.mutation, params)
        } else {
          info('wrong parameter for callback in select control')
        }
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(key) {
        this.inputValue = key
        this.setSelectedIndex()
      }
    },
    changed: {
      handler(count) {
        this.blink()
      }
    }
  }
}
</script>

<style lang="sass">
.cs-select
  +input
  position: relative
  display: flex
  align-items: flex-start
  justify-content: space-between
  .selected-option
    flex-grow: 1
    +ellipsis
  .dropdown-icon
    height: 0.625em
    width: 0.625em
    margin-top: .3125em
    margin-left: $form-input-indent
    border: 4px solid $form-control-color
    border-right: 0
    border-top: 0
    border-radius: 2px
    pointer-events: none
    transform: rotate(-45deg)
    transform-origin: center
    content: " "
    z-index: 4
  select
    -webkit-appearance: none
    -moz-appearance: none
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    padding: 0
    opacity: 0
    font-size: $form-font-size
    filter: alpha(opacity=0)
    visibility: visible
    cursor: pointer
    &::-ms-expand
      display: none
  &.is-inline
    justify-content: flex-start
    .selected-option
      flex-grow: 0
  &.is-disabled
    +disabled
    select
      cursor: default
    .dropdown-icon
      border-color: $form-disabled-color
  &:not(.is-disabled)
    &.has-error
      +error
      .dropdown-icon
        border-color: $form-error-color
  &.help
    +font('default')
    color: grey(5)
    +ellipsis
</style>