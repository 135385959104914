<template>
  <div
    :id="hash"
    :style="segmentStyle"
    :class="{ 'is-parallax' : isParallax }"
    class="cs-parallax-segment"
    ref="el">
      <cs-container
        v-if="isParallax"
        class="back"
        :class="{ 'is-fullwidth': fullWidth }"
        :style="speedStyle(speedBack)">
          <slot name="background"></slot>
      </cs-container>
      <cs-container
        v-if="isParallax"
        class="fore"
        :style="speedStyle(speedFore)"
        ref="fore">
          <slot name="foreground"></slot>
      </cs-container>
      <cs-container
        v-if="!isParallax"
        class="default"
        ref="default">
          <slot></slot>
      </cs-container>
  </div>
</template>

<script>

/**
 * Names slots as <template #background> and <template #foreground>
 * are used for different scrolling speeds (parallax effect), 
 * where as if only a default slot is given, the section is
 * a non-parallax section
 */
export default {
  name: 'cs-parallax-segment',
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    fullWidth: {
      type: Boolean,
      default: false
    },

    // fit height to content by setting min-height, only relevant for parallax
    fitContent: {
      type: Boolean,
      default: false
    },

    // set speed of scrolling element inside parallax container
    // speed = 0 original scrolling speed
    // speed > 0 scrolls faster
    // speed < 0 scrolls slower
    // @attention: set to 0 on last element on page, set speedFore instead
    speedBack: {
      type: Number,
      default: 0
    },
    speedFore: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      direction: null,
      segmentStyle: null,
      scroll: {
        visible: null,
        view: null,
        top: null,
        bottom: null
      }
    }
  },
  mounted () {
    this.setSegmentStyle()
  },
  computed: {
    isParallax () {
      return this.$slots.background && this.$slots.foreground
    }
  },
  methods: {
    speedStyle (speed) {
      if (!speed) {
        //return null
      }
      var translateZ = speed * 10 + 'px'
      var scale = 1 - (speed / 10)
      return 'transform: translateZ(' + translateZ + ') scale(' + scale + ')'
    },
    checkPosition (init, direction) {
      var pos = this.$dom.isScrolledIntoView(this.$refs.el, this.$parent.$refs.el)
      if (init) {
        this.direction = direction || 'down'
        fn.each(this.scroll, (value, key) => {
          this.scroll[key] = null
        })
      }

      // Inform parent and other elements by event
      fn.each(pos, (value, key) => {
        if (this.scroll[key] !== value) {
          this.scroll[key] = value
          if (value) {
            this.$trigger('parallax-segment/' + key, {
              hash: this.hash,
              direction: this.direction
            })
          }
        }
      })
    },
    setSegmentStyle () {
      var res = []
      res.push('z-index: ' + (this.positionZ + 100))
      if (this.isParallax && this.fitContent) {
        var style = getComputedStyle(this.$refs.fore.$el)
        var height = fn.toInteger(style.paddingTop.split('px')[0]) + fn.toInteger(style.paddingBottom.split('px')[0])
        fn.each(this.$refs.fore.$el.children, (el) => {
          height += this.$dom.getHeight(el)
        })
        res.push('min-height: ' + Math.ceil(height) + 'px')
      }
      this.segmentStyle = res.join(';')
    },
    scrollHandler (Event) {
      this.checkPosition(
        this.direction !== Event.detail.direction,
        Event.detail.direction
      )
    },
    windowSizeHandler (Event) {
      if (this.isParallax && this.fitContent) {
        this.setSegmentStyle()
      }
    }
  },
  events: {
    'window/scroll': 'scrollHandler',
    'scroll/scrolled': 'scrollHandler',
    'window/width': 'windowSizeHandler',
    'window/height': 'windowSizeHandler'
  }
}
</script>

<style lang="sass">
.cs-parallax-segment
  position: relative
  width: 100vw
  &.is-parallax
    height: 100vh // default height, because without height parallax doesn't work. Can be overwritten is parent style.
    transform-style: preserve-3d
  .back,
  .fore
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
  .fore
    z-index: $z-index-base
  .back
    z-index: $z-index-base - 1
</style>
