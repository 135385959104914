<template>
  <div
    :class="{
      'has-error': hasError,
      'is-disabled': disabled
    }"
    class="cs-input">
      <input
        type="text"
        v-model="inputValue"
        :placeholder="placeholder"
        :maxlength="maxlengthCalc"
        :disabled="disabled"
        @input="inputHandler($event)"
        @change="changeHandler($event)"
        @focus="focusHandler($event)"
        @keydown="keyDownHandler($event)"
        ref="control" />
      <span v-if="hasHelp" class="help">
        {{ help }}
      </span>
  </div>
</template>

<script>
export default {
  name: 'cs-input',
  props: {
    value: {
      type: String,
      default: ''
    },

    /**
     * optional store action/mutation on value change
     * { action: foo, mutation: bar [, more: callback-params]}
     * otherwise value will be emitted via v-model
     */ 
    callback: {
      type: Object
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    },
    isEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: null,
      inputValue: ''
    }
  },
  computed: {
    hasError () {
      return this.error || !this.valid(this.inputValue)
    },
    hasHelp () {
      return fn.isString(this.help)
    },
    maxlengthCalc () {
      if (!this.isEmail && fn.isInteger(this.max, 1) && this.max > this.min) {
        return this.max
      } else {
        return null
      }
    }
  },
  methods: {
    valid (value) {
      if (this.isEmail) {
        return fn.isEmpty(value) || fn.isEmail(value)
      } else {
        return fn.isEmpty(value) || fn.isString(value, this.min, this.maxlengthCalc)
      }
    },
    format (text) {
      if (this.isEmail) {
        text = fn.lower(text)
      }
      return fn.trim(text)
    },
    unformat (input) {
      return fn.toString(input)
    },
    typecheck (Event) {
      if (fn.getFunctionKey(Event)) {
        return true
      }
      return true
    },
    focusHandler (Event) {
      this.$refs.control.select()
    },
    inputHandler (Event) {
      if (!this.disabled) {
        this.text = this.unformat(this.inputValue)
        this.publish(this.text || null, 'input')
      }
    },
    changeHandler (Event) {
      if (!this.disabled && this.valid(this.inputValue)) {
        this.inputValue = this.format(this.text)
        this.publish(this.text, 'change')
      }
    },
    keyDownHandler (Event) {
      if (!this.typecheck(Event)) {
        event.preventDefault()
      }
    },
    publish (value, event) {
      if (fn.isObject(this.callback)) {
        var params = fn.assign({
          value: value,
          event: event
          }, this.callback
        )
        if(fn.isString(this.callback.action)) {
          this.$store.dispatch(this.callback.action, params)
        } else if(fn.isString(this.callback.mutation)) {
          this.$store.commit(this.callback.mutation, params)
        } else {
          info('wrong parameter for callback in text control')
        }
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(text) {
        if (text !== this.text) {
          this.text = text
          this.inputValue = this.format(text)
        }
      }
    }
  }
}
</script>

<style lang="sass">
.cs-input
  position: relative
  display: flex
  align-items: flex-start
  input
    +input
  &.is-disabled
    input
      +disabled
  &:not(.is-disabled)
    &.has-error
      input
        +error
  &.help
    +font('default')
    color: grey(5)
    +ellipsis
</style>