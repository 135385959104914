<template>
  <div class="label cs-label">
    <slot></slot>
    <span v-if="required" class="required">*</span>
  </div>
</template>

<script>

export default {
  name: 'cs-label',
  props: {
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showTooltip: false
    }
  }
}
</script>

<style lang="sass">
.cs-label
  display: flex
  align-items: baseline
  padding: 3px 0
  +font('tool', 'semibold')
  +ellipsis
  .required
    padding-left: 2px
    color: color('warning')
  &.has-error
    color: $form-error-color
  &.is-hidden
    display: none
  &.is-visible
    display: inline-flex
</style>