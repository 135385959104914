<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    class="cs-page-home-chorus">
      <div class="intro">
        <cs-row class="text">
          <cs-col class="is-8 is-offset-2 is-10-md is-offset-1-md is-12-sm is-no-offset-sm">
            <h2>{{ content.headline }}</h2>
            <cs-text :content="content.copy" />
          </cs-col>
        </cs-row>
      </div>
      <div class="switch">
        <cs-row>
          <cs-col>
            <cs-button
              @click="toggleTabHander($event, 'members')"
              :class="{ 'is-outline': currentTab !== 'members' }"
              class="is-primary is-medium">
                {{ content.buttonmembers }}
            </cs-button>
            <cs-button
              @click="toggleTabHander($event, 'repertoire')"
              :class="{ 'is-outline': currentTab !== 'repertoire' }"
              class="is-primary is-medium">
                {{ content.buttonrepertoire }}
            </cs-button>
            <cs-button
              @click="toggleTabHander($event, 'barbershop')"
              :class="{ 'is-outline': currentTab !== 'barbershop' }"
              class="is-primary is-medium">
                {{ content.buttonbarbershop }}
            </cs-button>
          </cs-col>
        </cs-row>
      </div>
      <cs-slide v-if="members.length > 0" :active="currentTab === 'members'">
        <cs-row class="is-centered">
          <cs-col class="is-6 is-12-sm is-12-xs">
            <cs-row class="is-xs is-multiline is-centered members">
              <cs-col
                v-for="(member, i) in members"
                :key="i"
                class="is-4 is-3-sm is-3-xs member"
                v-tooltip="{
                  placement: 'top',
                  trigger: 'hover click',
                  content: member.content.title + ' - ' + memberVoice(member.content)
                }">
                  <cs-image
                    :file="member.content.pageimage"
                    size="member.preview"
                    sizeHover="member.hover"
                    @load="memberLoadHandler($event, i)" />
              </cs-col>
            </cs-row>
          </cs-col>
        </cs-row>
      </cs-slide>
      <cs-slide :active="currentTab === 'repertoire'">
        <cs-row class="no-gap-sm repertoire">
          <cs-col class="is-6 left typo">
            <p 
              v-for="(song, i) in repertoireLeft"
              :key="i">{{ song.title }}</p>
          </cs-col>
          <cs-col class="is-6 right typo">
            <p 
              v-for="(song, i) in repertoireRight"
              :key="i">{{ song.title }}</p>
          </cs-col>
        </cs-row>
      </cs-slide>
      <cs-slide :active="currentTab === 'barbershop'">
        <cs-row class="barbershop text">
          <cs-col class="is-8 is-offset-2 is-10-md is-offset-1-md is-12-sm is-no-offset-sm">
            <h3>{{ content.headlinebarbershop }}</h3>
            <cs-text :content="content.copybarbershop" />
          </cs-col>
        </cs-row>
      </cs-slide>
    </cs-parallax-segment>
</template>

<script>
import { VTooltip } from 'v-tooltip'
import Deferred from '@/services/Deferred'
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-chorus',
  components: {
    CsParallaxSegment
  },
  directives: {
    tooltip: VTooltip
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    }
  },
  data() {
    return {
      members: [],
      hoverKey: null,
      currentTab: 'members',
      moreinfoHeight: null,
      loadMembersPromise: null,
      loadImagesPromise: []
    }
  },
  created () {
    this.loadMembers()
  },
  mounted () {
    this.$trigger('home/load-promise', new Promise((resolve, reject) => {
      this.loadMembersPromise
        .then(() => {
          this.loadImagesPromise = []
          for (let i = 0; i < this.members.length; i++) {
            this.loadImagesPromise.push(new Deferred())
          }
          return Promise.all(this.loadImagesPromise)
        })
        .finally(() => {
          resolve()
        })
    }))
  },
  computed: {
    repertoireLeft () {
      return this.content.repertoire.slice(0, Math.ceil(this.content.repertoire.length/2))
    },
    repertoireRight () {
      return this.content.repertoire.slice(Math.ceil(this.content.repertoire.length/2))
    }
  },
  methods: {
    loadMembers () {
      this.loadMembersPromise = new Promise((resolve, reject) => {
        this.$api.children('/members', { limit: 99 })
        .then((response) => {
          this.members = response.data.children
          resolve()
        })
        .catch(() => {
          this.members = []
          reject()
        })
      })
    },
    memberImage (key) {
      return this.hoverKey === key ? 'member.hover' : 'member.preview'
    },
    memberVoice (member) {
      return member.voice ? this.$t('members.' + member.voice) : member.function
    },
    toggleTabHander (Event, tab) {
      this.currentTab = tab
    },
    memberLoadHandler (Event, i) {
      if (this.loadImagesPromise[i]) {
        this.loadImagesPromise[i].resolve()
      }
    }
  }
}
</script>

<style lang="sass">
.cs-page-home-chorus
  display: flex
  justify-content: center
  padding-top: headerheight()
  padding-bottom: m(6)
  background-color: white()
  .intro
    margin-bottom: m(3)
    h2
      margin-bottom: m(3)
      text-align: center
  .switch
    margin-bottom: m(3)
    .cs-col
      display: flex
      justify-content: center
      flex-wrap: wrap
      .cs-button
        margin: m(1) m(1)
  .members
    margin: 0 auto
    .member
      position: relative
      line-height: 0
      z-index: $z-index-base
  .repertoire
    p
      margin-bottom: m(2)
      +font('small', 'bold')
      text-transform: uppercase
    .left
      p
        text-align: right
  .barbershop
    h3
      margin-bottom: m(3)
      text-align: center

+md
  .cs-page-home-chorus
    padding-top: headerheight('md')

+sm
  .cs-page-home-chorus
    padding-top: headerheight('sm')
    .repertoire
      flex-wrap: wrap
      .left,
      .right
        width: 100%
        p
          margin-bottom: m(1)
          text-align: center

+xs
  .cs-page-home-chorus
    padding-top: headerheight('xs')
    .repertoire
      .left
        padding-bottom: 0

</style>
