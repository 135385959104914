<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    :fullWidth="true"
    :fitContent="true"
    :speedBack="-3"
    class="cs-page-home-join">
      <template #background>
        <cs-image
          :file="content.pageimage"
          :deferred="pageImagePromise"
          size="home.teaser"
          class="is-hero" />
      </template>
      <template #foreground>
        <div class="intro">
          <cs-row class="text">
            <cs-col class="is-8 is-offset-2 is-10-md is-offset-1-md is-12-sm is-no-offset-sm">
              <h2>{{ content.headline }}</h2>
              <cs-text :content="content.copy" />
            </cs-col>
          </cs-row>
        </div>
        <div class="details">
          <cs-row>
            <cs-col class="is-5 is-offset-1 is-6-md is-no-offset-md">
              <div class="box">
                <div class="typo">
                  <h3>{{ content.headlinerehersal }}</h3>
                  <cs-text :content="content.copyrehersal" />
                </div>
                <cs-link :link="content.emailrehersal">
                  <cs-button class="is-text is-white is-medium" icon="mail">{{ content.buttonrehersal }}</cs-button>
                </cs-link>
              </div>
            </cs-col>
            <cs-col class="is-5 is-6-md">
              <div class="box">
                <div class="typo">
                  <h3>{{ content.headlineaddress }}</h3>
                  <cs-text :content="content.copyaddress" />
                </div>
                <cs-link :link="content.linkmap">
                  <cs-button class="is-text is-white is-medium" icon="map">{{ content.buttonmap }}</cs-button>
                </cs-link>
              </div>
            </cs-col>
          </cs-row>
        </div>
      </template>
  </cs-parallax-segment>
</template>

<script>
import Deferred from '@/services/Deferred'
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-join',
  components: {
    CsParallaxSegment
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    }
  },
  data() {
    return {
      pageImagePromise: new Deferred()
    }
  },
  mounted () {
    this.$trigger('home/load-promise', this.pageImagePromise)
  },
}
</script>

<style lang="sass">
.cs-page-home-join
  height: 100vh
  .fore
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: headerheight()
    padding-bottom: m(10)
    .intro
      flex-shrink: 0
      h2
        margin-bottom: m(3)
        text-align: center
        color: white()
      p
        margin-bottom: m(3)
        +font('semibold')
        color: white()
        text-align: center
    .details
      flex-shrink: 0
      .box
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%
        padding: m(3)
        background-color: color('primary', .7)
        h3
          color: white()
        p
          color: white()
        .cs-button
          margin-top: m(1)

+md
  .cs-page-home-join
    .fore
      padding-top: headerheight('md')

+until(720px)
  .cs-page-home-join
    .fore
      .details
        .cs-row
          flex-wrap: wrap
          .cs-col
            width: 100%

+sm
  .cs-page-home-join
    .fore
      padding-top: headerheight('sm')
      padding-bottom: m(3)
      .intro
        min-height: 30vh

+xs
  .cs-page-home-join
    .fore
      padding-top: headerheight('xs')
</style>
