<template>
  <div
    class="cs-template-default">
      <cs-segment-header />
      <transition name="fade" mode="out-in">
        <component
          :is="currentPage"
          :key="currentKey"
          class="cs-page">
        </component>
      </transition>
      <div class="footer">
        <cs-links
          v-if="$store.state.navigation.footer.length > 0"
          :links="$store.state.navigation.footer"
          :maxLevel="1"
          class="nav-footer" />
      </div>
  </div>
</template>

<script>
import CsSegmentHeader from '@/components/segments/Header'

export default {
  name: 'cs-template-default',
  components: {
    CsSegmentHeader
  },
  computed: {
    currentPage () {
      return this.$router.getPage()
    },
    currentKey () {
      return this.$store.state.pagekey
    }
  }
}
</script>

<style lang="sass">
.cs-template-default
  z-index: $z-index-base
  .cs-page
    padding-top: headerheight()
    padding-bottom: m(3)
    transition: all .4s cubic-bezier(.55,0,.1,1)
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity .5s ease
    &.fade-enter,
    &.fade-leave-active
      opacity: 0
  .footer
    flex-shrink: 0
    padding-bottom: m(6)
    .nav-footer
      display: flex
      justify-content: center
      flex-wrap: wrap
      li
        margin: 0 m(1)
        +font('small', 'semibold')

+md
  .cs-template-default
    .cs-page
      padding-top: headerheight('md')

+sm
  .cs-template-default
    .cs-page
      padding-top: headerheight('sm')

+xs
  .cs-template-default
    .cs-page
      padding-top: headerheight('xs')

</style>
